import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Countdown from 'react-countdown';
import { loadStripe } from '@stripe/stripe-js';

import { Icon as Iconify } from '@iconify/react';
import makeStyles from '@mui/styles/makeStyles';
import { DateCalendar, DayCalendarSkeleton, PickersDay } from '@mui/x-date-pickers';
import {
  CssBaseline, Box, Container,
  CircularProgress, Card, CardContent,
  Typography, Button, Toolbar, AppBar, Tab,
  Table, TableBody, TableCell, TableContainer, TableRow,
  AvatarGroup, Avatar, ButtonGroup,
  List, ListSubheader, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListItemButton,
  IconButton, Collapse, Chip,
  Dialog, DialogTitle, DialogActions, DialogContent,
  Stepper, Step, StepLabel, StepContent,
  TextField, Divider,
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails,
  Paper, Stack,
  Badge, Link, Grid2 as Grid, useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneIcon from '@mui/icons-material/Phone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import CategoryCard from './CategoryCard.js';
import SwipeableEdgeDrawer from './SwipeableEdgeDrawer.js';

import moment from 'moment-timezone';
import 'moment/locale/en-au';
require('moment-recur');

const axios = require('axios');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh'
  },
  menuButton: {
    marginRight: 16,
  },
  title: {
    flexGrow: 1,
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  borderRight: 0,
  borderBottom: 0,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const steps = [
  {
    label: 'Select Location',
    // description: 'Where would you like to book an appointment?'
  },
  {
    label: 'Choose Your Services',
    // description: 'Which services would you like to have?'
  },
  {
    label: 'Choose Your Staff',
    // description: 'Pick your favourite staff. If not, we will auto assign available staff for your appointment.',
    descriptionClass: 'Staff Preference is not available for Class Booking. You can continue to next step.'
  },
  {
    label: 'Choose Date & Time',
    // description: 'When would this appointment be?',
    descriptionClass: 'When would this class be?'
  },
  {
    label: 'Booking Summary',
    description: 'Here are your appointment details.',
  },
];

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [loading, setLoading] = useState(true);
  const [linkValid, setLinkValid] = useState(true);

  const [activeStep, setActiveStep] = useState(0);

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(true);
  const [isCancel, setIsCancel] = useState(false);
  const [tabValue, setTabValue] = useState("services");
  const [summaryCollapse, setSummaryCollapse] = useState(true);
  const [searchingTime, setSearchingTime] = useState(false);
  const [searchedTime, setSearchedTime] = useState(false);
  const [requirePrePayment, setRequirePrePayment] = useState(false);
  const [closedDay, setClosedDay] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [matchAllTags, setMatchAllTags] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);

  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#e6e6e6');
  const [bannerImage, setBannerImage] = useState('');
  const [businessName, setBusinessName] = useState('Online Booking');
  const [stripeDefaultConnectedId, setStripeDefaultConnectedId] = useState('');
  const [stripeConnectedId, setStripeConnectedId] = useState('');
  const [afterHoursMinute, setAfterHoursMinute] = useState(30);
  const [allowHalfHourOnly, setAllowHalfHourOnly] = useState(false);
  const [timeSlotInterval, setTimeSlotInterval] = useState(15);
  const [prePayment, setPrePayment] = useState(false);
  const [prePaymentType, setPrePaymentType] = useState('');
  const [prePaymentAmount, setPrePaymentAmount] = useState('');
  const [addFees, setAddFees] = useState(false);
  const [conditionalPrepayment, setConditionalPrepayment] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [minimumBookingDays, setMinimumBookingDays] = useState(0);
  const [minimumBookingHours, setMinimumBookingHours] = useState(1);
  const [maximumBookingDays, setMaximumBookingDays] = useState(90);
  const [onlineBookingAnnouncementToggle, setOnlineBookingAnnouncementToggle] = useState(false);
  const [onlineBookingAnnouncement, setOnlineBookingAnnouncement] = useState('');
  const [announcementDialogOpen, setAnnouncementDialogOpen] = useState(false);
  const [reduceGaps, setReduceGaps] = useState(false);
  const [sites, setSites] = useState([]);
  const [siteExpand, setSiteExpand] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [categoryCardExpand, setCategoryCardExpand] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceBundles, setServiceBundles] = useState([]);
  const [serviceTiers, setServiceTiers] = useState([]);
  const [serviceTags, setServiceTags] = useState([]);
  const [resources, setResources] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [rosters, setRosters] = useState([]);
  const [currency, setCurrency] = useState('$');
  const [checkoutCurrency, setCheckoutCurrency] = useState('AUD');
  const [onlineVoucherEnabled, setOnlineVoucherEnabled] = useState(false);
  const [onlineVoucherUrl, setOnlineVoucherUrl] = useState('');
  const [onlineBookingsUrl, setOnlineBookingsUrl] = useState('');

  // time slots
  const [morning, setMorning] = useState([]);
  const [afternoon, setAfternoon] = useState([]);
  const [evening, setEvening] = useState([]);
  const [busyBlocks, setBusyBlocks] = useState([]);
  const [countdownKey, setCountdownKey] = useState(1);
  const [countdownTime, setCountdownTime] = useState(Date.now() + 600000)
  const countdownRef = useRef(null);

  // classes
  const [availableClasses, setAvailableClasses] = useState([]);
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useState(null);

  // booking info
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
  const [selectedTime, setSelectedTime] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [expectedDuration, setExpectedDuration] = useState(0);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [amountToPay, setAmountToPay] = useState(0);
  const [employeeForServices, setEmployeeForServices] = useState([]);

  // error text
  const [firstnameError, setFirstnameError] = useState('');
  const [lastnameError, setLastnameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  // datepicker
  const [isDatePickerLoading, setIsDatePickerLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const requestAbortController = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // if (urlParams.has('result')) {
    //   if (urlParams.has('calendarEventId')) {
    //     // class
    //     // axios.post('https://app.clientdiary.com/methods/updateClassAfterDeposit', [urlParams.get('calendarEventId'), urlParams.get('clientId'), urlParams.get('result'), urlParams.get('amount')])
    //     // axios.post('http://localhost:3000/methods/updateClassAfterDeposit', [urlParams.get('calendarEventId'), urlParams.get('clientId'), urlParams.get('result'), urlParams.get('amount')])
    //   } else {
    //     // appointment
    //     // axios.post('https://app.clientdiary.com/methods/updateAppointmentAfterDeposit', [urlParams.get('appointmentId'), urlParams.get('result'), urlParams.get('amount')])
    //   }
    // }

    if (urlParams.has('bookingsUrl')) {
      axios.post('https://app.clientdiary.com/methods/getBusinessDetails', [urlParams.get('bookingsUrl')])
      // axios.post('http://localhost:3000/methods/getBusinessDetails', [urlParams.get('bookingsUrl')])
      .then(function (response) {
        const result = response.data;
        
        if (result.available === false || (!result.testAccount && (result.subscription === undefined || (result.subscription.status !== 'active' && result.subscription.status !== 'trialing' && result.subscription.status !== 'past_due')))) {
          setLinkValid(false);
          setLoading(false);
        } else {
          let categoryCardExpand = [];
          result.serviceCategories.forEach(category => categoryCardExpand.push(false));

          let siteExpand = [];
          result.sites.forEach(site => siteExpand.push(false));

          setBackgroundImage(result.backgroundImage === undefined ? '' : result.backgroundImage);
          setBackgroundColor(result.backgroundColor === undefined ? '#e6e6e6' : result.backgroundColor);
          setBannerImage(result.bannerImage === undefined ? '' : result.bannerImage);
          setBusinessName(result.businessName);
          setStripeDefaultConnectedId(result.stripeConnectedId);
          setStripeConnectedId(result.stripeConnectedId);
          setAfterHoursMinute(result.afterHoursMinute === undefined ? 30 : Number(result.afterHoursMinute));
          setAllowHalfHourOnly(result.allowHalfHourOnly === undefined ? false : result.allowHalfHourOnly);
          setTimeSlotInterval(result.timeSlotInterval === undefined ? 15 : result.timeSlotInterval);
          setPrePayment(result.prePayment);
          setPrePaymentType(result.prePaymentType);
          setPrePaymentAmount(result.prePaymentAmount);
          setAddFees(result.addFees === undefined ? false : result.addFees);
          setConditionalPrepayment(result.conditionalPrepayment);
          setTermsAndConditions(result.termsAndConditions);
          setMinimumBookingDays(result.minimumBookingDays === undefined ? 0 : result.minimumBookingDays);
          // setMinimumBookingHours(result.minimumBookingHours === undefined ? 0 : result.minimumBookingHours);
          setMaximumBookingDays(result.maximumBookingDays === undefined ? 90 : result.maximumBookingDays);
          setOnlineBookingAnnouncementToggle(result.onlineBookingAnnouncementToggle === undefined ? false : result.onlineBookingAnnouncementToggle);
          setOnlineBookingAnnouncement(result.onlineBookingAnnouncement === undefined ? '' : result.onlineBookingAnnouncement);
          setAnnouncementDialogOpen(result.onlineBookingAnnouncementToggle && result.onlineBookingAnnouncement !== '' ? true : false);
          setReduceGaps(result.reduceGaps === undefined ? false : result.reduceGaps);
          setSites(result.sites);
          setSiteExpand(siteExpand);
          setServiceCategories(result.serviceCategories);
          setCategoryCardExpand(categoryCardExpand);
          setServices(result.services);
          setServiceBundles(result.serviceBundles);
          setServiceTiers(result.serviceTiers);
          setServiceTags(result.serviceTags);
          setResources(result.resources);
          setClassesList(result.classes);
          setTabValue(result.services.length === 0 && result.classes.length !== 0 ? 'classes' : 'services');
          setEmployees(result.employees);
          setRosters(result.rosters);
          setCurrency(result.currency);
          setCheckoutCurrency(result.checkoutCurrency);
          setOnlineVoucherEnabled(result.onlineVoucherEnabled);
          setOnlineVoucherUrl(result.onlineVoucherUrl);
          setOnlineBookingsUrl(urlParams.get('bookingsUrl'));

          if (result.sites.length === 1) {
            selectSite(result.sites[0], result.stripeConnectedId);
          }

          if (urlParams.has('result')) {
            setSubmitted(true);
            setSuccess(urlParams.get('result') === 'success' ? true : false);
            setIsCancel(urlParams.get('result') === 'cancel' ? true : false);
            setSelectedSite(urlParams.get('selectedSiteId') === undefined ? null : result.sites.find(s => s._id === urlParams.get('selectedSiteId')));
          }

          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      setLinkValid(false);
      setLoading(false);
    }

    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const getComputedStyle = () => {
    let mainStyle = {
      flex: 1
    };

    let backgroundImageStyle = '';
    if (backgroundImage !== '') {
      backgroundImageStyle = 'url(' + backgroundImage + ')';
    }

    if (backgroundImage.includes('Default')) {
      mainStyle.backgroundColor = backgroundColor;
    } else if (backgroundImage !== '') {
      mainStyle.backgroundImage = backgroundImageStyle;
      mainStyle.backgroundRepeat = 'repeat';
    }

    return mainStyle
  }

  const getAuthor = () => {
    let author = '';
    
    if (backgroundImage !== '') {
      if (backgroundImage.includes('BSGStudio')) {
        author = 'BSGStudio';
      } else if (backgroundImage.includes('freedesignfile')) {
        author = 'freedesignfile';
      } else if (backgroundImage.includes('peecheey')) {
        author = 'peecheey';
      } else if (backgroundImage.includes('webdesignhot')) {
        author = 'webdesignhot';
      }
    }

    return author
  }

  const handleNext = () => {
    if (activeStep === 1 && (employees.filter(employee => employee.siteIds.includes(selectedSite._id) && employee.siteIdsOnlineBooking.includes(selectedSite._id)).length === 1 || selectedClass.length !== 0)) {
      // if next step is select employee AND there is only 1 employee then skip it and go straight to select date/time step
      handleMonthChange(moment().add(minimumBookingDays, 'days').startOf('day'));
      setActiveStep(3)
    } else {
      if (activeStep === 2) {
        handleMonthChange(moment().add(minimumBookingDays, 'days').startOf('day'));
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 3 && selectedClass.length !== 0) {
      setActiveStep(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const selectSite = (site, defaultStripe) => {
    changeLocation();
    
    moment.tz.setDefault(site.timezone);

    let closedDay = [];
    site.openingHours.forEach((day, index) => {
      if (day.active === false) {
        closedDay.push(index);
      }
    });

    if (site.usingStripe === 'separate' && site.stripe_user_id !== undefined) {
      setStripeConnectedId(site.stripe_user_id);
    } else {
      setStripeConnectedId(defaultStripe);
    }

    setSelectedSite(site);
    setClosedDay(closedDay);
    handleNext();
  }

  const changeLocation = () => {
    setSelectedSite(null);
    setSelectedServices([]);
    setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
    setSelectedTime(false);
    setSelectedDateTime(null);
    setSelectedEmployees([]);
    setExpectedDuration(0);
    setSearchedTime(false);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);
  }

  const handleSelectTag = (tagId) => {
    if (selectedTagIds === undefined) {
      setSelectedTagIds([tagId])
    } else {
      const index = selectedTagIds.indexOf(tagId);

      if (index === -1) {
        setSelectedTagIds([...selectedTagIds, tagId]);
      } else {
        let tempSelectedTagIds = [...selectedTagIds];
        tempSelectedTagIds.splice(index, 1);
        setSelectedTagIds(tempSelectedTagIds);
      }
    }
  }

  const handleSelectService = (serviceId) => {
    if (selectedClass.length !== 0) {
      alert('Service cannot be booked with class. Please remove selected class and try again.')
    } else {
      const service = services.find(service => service._id === serviceId);
      setSelectedServices([...selectedServices, service]);

      let tempExpectedDuration = expectedDuration;
      service.timeSlots.forEach(slot => tempExpectedDuration += Number(slot.duration));
      setExpectedDuration(tempExpectedDuration);

      setSelectedEmployees([...selectedEmployees, 'any']);
      setSearchedTime(false);
      setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
      setSelectedTime(false);
      setSelectedDateTime(null);
      setMorning([]);
      setAfternoon([]);
      setEvening([]);
    }
  }

  const handleExpandSiteCard = (index) => {
    let tempSiteExpand = [...siteExpand]
    if (tempSiteExpand[index] === true) {
      // closing site opening hours section
      tempSiteExpand[index] = false;
    } else {
      // opening site opening hours section
      tempSiteExpand = [];

      siteExpand.forEach(site => {
        tempSiteExpand.push(false);
      });

      tempSiteExpand[index] = !tempSiteExpand[index];
    }

    setSiteExpand(tempSiteExpand);
  }

  const handleExpandCategoryCard = (index) => {
    let tempCategoryCardExpand = [...categoryCardExpand]
    if (tempCategoryCardExpand[index] === true) {
      // closing category
      tempCategoryCardExpand[index] = false;
    } else {
      // opening category
      tempCategoryCardExpand = [];

      categoryCardExpand.forEach(category => {
        tempCategoryCardExpand.push(false);
      });

      tempCategoryCardExpand[index] = !tempCategoryCardExpand[index];
    }

    setCategoryCardExpand(tempCategoryCardExpand);
  }

  const selectBundle = (bundle) => {
    if (selectedClass.length !== 0) {
      alert('Bundle cannot be booked with class. Please remove selected class and try again.')
    } else {
      let tempSelectedServices = [...selectedServices];
      let tempExpectedDuration = expectedDuration;
      let tempSelectedEmployees = [...selectedEmployees];

      bundle.servicesInBundle.forEach(service => {
        service.bundleName = bundle.bundleName;
        service.onlineBookingDescription = 'Part of "' + bundle.bundleName + '" bundle';
        tempSelectedServices.push(service);
        service.timeSlots.forEach(slot => tempExpectedDuration += Number(slot.duration));
        tempSelectedEmployees.push('any');
      });

      setSelectedServices(tempSelectedServices);
      setExpectedDuration(tempExpectedDuration);
      setSelectedEmployees(tempSelectedEmployees);
      setSearchedTime(false);
      setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
      setSelectedTime(false);
      setSelectedDateTime(null);
      setMorning([]);
      setAfternoon([]);
      setEvening([]);
    }
  }

  const handleRemoveService = (serviceId) => {
    const service = selectedServices.find(s => s._id === serviceId);
    const index = selectedServices.findIndex(s => s._id === serviceId);

    removeSelectedService(service, index);
  }

  const removeSelectedService = (service, index) => {
    let tempSelectedServices = [...selectedServices];
    tempSelectedServices.splice(index, 1);
    setSelectedServices(tempSelectedServices);

    let tempExpectedDuration = expectedDuration;
    service.timeSlots.forEach(slot => tempExpectedDuration -= Number(slot.duration));
    setExpectedDuration(tempExpectedDuration);

    let tempSelectedEmployees = [...selectedEmployees];
    tempSelectedEmployees.splice(index, 1);
    setSelectedEmployees(tempSelectedEmployees);

    setSearchedTime(false);
    setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);

    if (tempSelectedServices.length === 0) {
      setActiveStep(1)
    }
  }

  const handleRemoveBundle = (bundle) => {
    let tempSelectedServices = [...selectedServices];
    let tempSelectedEmployees = [...selectedEmployees];
    let tempExpectedDuration = expectedDuration;
    const seen = new Set(); // To track which values have been removed

    // Loop through array 'bundle.servicesInBundle' and remove the first match from 'tempSelectedServices' for each value in 'bundle.servicesInBundle'
    bundle.servicesInBundle.forEach(serviceInBundle => {
      // Find the index of the first object in 'tempSelectedServices' that matches the current object in 'bundle.servicesInBundle' and hasn't been removed yet
      const index = tempSelectedServices.findIndex(tempService => tempService._id === serviceInBundle._id && !seen.has(tempService._id));
      
      if (index !== -1) {
        tempSelectedServices.splice(index, 1); // Remove the object from 'tempSelectedServices' at the found index
        tempSelectedEmployees.splice(index, 1);
        serviceInBundle.timeSlots.forEach(slot => tempExpectedDuration -= Number(slot.duration));
        seen.add(serviceInBundle._id); // Mark the value as removed
      }
    });

    setSelectedServices(tempSelectedServices);
    setExpectedDuration(tempExpectedDuration);
    setSelectedEmployees(tempSelectedEmployees);

    setSearchedTime(false);
    setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);

    if (tempSelectedServices.length === 0) {
      setActiveStep(1)
    }
  }

  const removeSelectedServiceBundle = (service, index) => {
    const selectedBundle = serviceBundles.find(bundle => bundle.bundleName === service.bundleName);
    handleRemoveBundle(selectedBundle);
  }

  const removeSelectedClass = (c, index) => {
    setSelectedClass([]);
    setAvailableClasses([]);
    setExpectedDuration(0);
    setSelectedEmployees([]);
    setSelectedCalendarEvent(null);
    setSearchedTime(false);
    setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);
  }

  const selectClass = (c) => {
    if (selectedClass.length !== 0) {
      alert('You can only book in one class. Please remove the selected class and try again.');
    } else {
      if (selectedServices.length === 0) {
        setSelectedClass([c]);
        setExpectedDuration(c.classDuration);
        setSelectedEmployees([...selectedEmployees, 'any']);
        setSearchedTime(false);
        setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
        setSelectedTime(false);
        setSelectedDateTime(null);
        setMorning([]);
        setAfternoon([]);
        setEvening([]);
      } else {
        alert('Class cannot be booked with services. Please remove selected services and try again.')
      }
    }
  }

  const handleRequestEmployee = (serviceIndex, employeeId) => {
    let tempSelectedEmployees = [...selectedEmployees];
    tempSelectedEmployees[serviceIndex] = employeeId;
    setSelectedEmployees(tempSelectedEmployees);

    setSearchedTime(false);
    setSelectedDate(moment().add(minimumBookingDays, 'days').startOf('day').toDate());
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);
  }

  const handleSelectDate = (newDate) => {
    setSearchedTime(false);
    setSelectedDate(newDate.startOf('day').toDate());
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);
  }

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setSearchedTime(false);
    setSelectedDate(date.startOf('day').toDate());
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);
    setIsDatePickerLoading(true);
    setHighlightedDays([]);

    fetchHighlightedDays(date);
  }

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    axios.post('https://app.clientdiary.com/methods/getBusyTimesMonth', [selectedSite, moment(date).startOf('month'), moment(date).endOf('month')], { signal: controller.signal })
    // axios.post('http://localhost:3000/methods/getBusyTimesMonth', [selectedSite, moment(date).startOf('month'), moment(date).endOf('month')], { signal: controller.signal })
    .then((response) => {
      // if (response.data.length !== 0) {
        // there are busyBlocks in the viewing month => loop through each day in the month
        let daysAvailable = [];

        for (let m = moment(date).startOf('month'); m.isBefore(moment(date).endOf('month')); m.add(1, 'days')) {
          // get events that day
          const eventsInDate = response.data.filter(ev =>
            moment(ev.start).isBetween(m, moment(m).add(1, 'days')) ||
            moment(ev.end).isBetween(m, moment(m).add(1, 'days')) ||
            (moment(ev.start).isBefore(m) && moment(ev.end).isAfter(moment(m).add(1, 'days')))
          );

          // if (eventsInDate.length > 0) {
            // there are events => get available slots
            const anySlots = getAvailableSlots(eventsInDate, moment(m).toDate(), 'month');
            if (anySlots) {
              daysAvailable.push(m.date())
            }
          // } else {
          //   // no events => day is available
          //   daysAvailable.push(m.date())
          // }
        }

        setHighlightedDays(daysAvailable);
      // }

      setIsDatePickerLoading(false);
    })
    .catch((error) => {
      // ignore the error if it's caused by `controller.abort`
      if (error.name !== 'AbortError') {
        throw error;
      }
    })

    requestAbortController.current = controller;
  };

  const getAvailableSlots = (result, date, type) => {
    const workingEmployees = employees.filter(employee => employee.siteIds.includes(selectedSite._id) && employee.siteIdsOnlineBooking.includes(selectedSite._id));

    const dayOfWeek = moment(date).day();
    let openingHours = selectedSite.openingHours[dayOfWeek].values;

    // if (workingEmployees.length === 1) {
    //   const roster = rosters.find(ros => ros.employeeId === workingEmployees[0]._id && ros.siteId === selectedSite._id);
    //   if (roster !== undefined) {
    //     openingHours = roster.rosters[dayOfWeek].values;
    //   }
    // }

    const start = moment(date).hour(openingHours.min).minute(openingHours.min % 1 === 0 ? 0 : 30).second(0).millisecond(0);
    const end = moment(date).hour(openingHours.max).minute(openingHours.max % 1 === 0 ? 0 : 30).second(0).millisecond(0);

    // add rosters to busyBlocks result
    workingEmployees.forEach(employee => {
      const roster = rosters.find(ros => ros.employeeId === employee._id && ros.siteId === selectedSite._id);
      if (roster !== undefined) {
        // get roster of this day of week
        const dayOfWeekRoster = roster.rosters[dayOfWeek];

        // check recurring
        if (roster.recurring !== undefined) {
          // has recurring roster => check if this selected date match any recurring
          const currentRecurring = roster.recurring.find(recur => {
            let lengthInterval = null;
            if (recur.endDate !== undefined) {
              // lengthInterval = moment(recur.startDate, 'DD/MM/YYYY').recur(moment(recur.endDate, 'DD/MM/YYYY')).every(recur.interval, recur.repeatType);
              lengthInterval = moment.utc(recur.startDate, 'DD/MM/YYYY').recur(moment.utc(recur.endDate, 'DD/MM/YYYY')).every(recur.interval, recur.repeatType);
            } else {
              // lengthInterval = moment(recur.startDate, 'DD/MM/YYYY').recur().every(recur.interval, recur.repeatType);
              lengthInterval = moment.utc(recur.startDate, 'DD/MM/YYYY').recur().every(recur.interval, recur.repeatType);
            }

            if (recur.except !== undefined) {
              recur.except.forEach(exception => lengthInterval.except(moment.utc(exception, 'DD/MM/YYYY')));
            }

            // return lengthInterval.matches(moment(date).startOf('day'))
            const dateToCheck = moment(date).format('DD/MM/YYYY');
            return lengthInterval.matches(moment.utc(dateToCheck, 'DD/MM/YYYY'))
          });

          if (currentRecurring !== undefined) {
            // selected date is in a recurrence
            if (currentRecurring.values.min === '' || currentRecurring.values.max === '') {
              // not working, create a busyBlock for the whole day
              result.push({
                start: moment(start).startOf('day').toDate(),
                end: moment(end).endOf('day').toDate(),
                employeeId: employee._id
              });
            } else {
              // working => check if working hours are different from site opening hours, if there is, create a busyBlock for it
              let startHour = currentRecurring.values.min - (currentRecurring.values.min % 1);
              let startMinute = 0;
              let fractionStart = currentRecurring.values.min - startHour;
              if (fractionStart === 0.25) {
                startMinute = 15;
              } else if (fractionStart === 0.5) {
                startMinute = 30;
              } else if (fractionStart === 0.75) {
                startMinute = 45;
              }

              const workStart = moment(date).hour(currentRecurring.values.min).minute(startMinute).second(0).millisecond(0);
              if (workStart.isAfter(start)) {
                // if workStart after the site openingHours start, create a busyBlock from site opening time to workStart
                result.push({
                  start: moment(start).toDate(),
                  end: moment(workStart).toDate(),
                  employeeId: employee._id
                });
              }

              let endHour = currentRecurring.values.max - (currentRecurring.values.max % 1);
              let endMinute = 0;
              let fractionEnd = currentRecurring.values.max - endHour;
              if (fractionEnd === 0.25) {
                endMinute = 15;
              } else if (fractionEnd === 0.5) {
                endMinute = 30;
              } else if (fractionEnd === 0.75) {
                endMinute = 45;
              }

              const workEnd = moment(date).hour(currentRecurring.values.max).minute(endMinute).second(0).millisecond(0);
              if (workEnd.isBefore(end)) {
                // if workEnd before the site openingHours end, create a busyBlock from workEnd to site closing time
                result.push({
                  start: moment(workEnd).toDate(),
                  end: moment(end).toDate(),
                  employeeId: employee._id
                });
              }

              // check for lunch break, if there is, create a busyBlock for it
              if (Number(dayOfWeekRoster.break) !== 0) {
                let lunchHour = (roster.dailyBreak[2] === 'pm' && roster.dailyBreak[0] < 12) ? roster.dailyBreak[0] + 12 : roster.dailyBreak[0];
                let lunchStart = moment(date).hour(lunchHour).minute(roster.dailyBreak[1]).second(0).millisecond(0);
                let lunchEnd = moment(lunchStart).add(Number(dayOfWeekRoster.break), 'minutes');
                result.push({
                  start: moment(lunchStart).toDate(),
                  end: moment(lunchEnd).toDate(),
                  employeeId: employee._id
                });
              }
            }

            return;
          }
        }

        // check custom
        if (roster.custom !== undefined) {
          // has custom roster => check if this selected date match any custom
          let customRoster = roster.custom.find(x => x.date === moment(date).format('DD/MM/YYYY'));
          if (customRoster !== undefined) {
            // selected date is a custom
            if (customRoster.values.min === '' || customRoster.values.max === '') {
              // not working, create a busyBlock for the whole day
              result.push({
                start: moment(start).startOf('day').toDate(),
                end: moment(end).endOf('day').toDate(),
                employeeId: employee._id
              });
            } else {
              // working => check if working hours are different from site opening hours, if there is, create a busyBlock for it
              let startHour = customRoster.values.min - (customRoster.values.min % 1);
              let startMinute = 0;
              let fractionStart = customRoster.values.min - startHour;
              if (fractionStart === 0.25) {
                startMinute = 15;
              } else if (fractionStart === 0.5) {
                startMinute = 30;
              } else if (fractionStart === 0.75) {
                startMinute = 45;
              }

              const workStart = moment(date).hour(customRoster.values.min).minute(startMinute).second(0).millisecond(0);
              if (workStart.isAfter(start)) {
                // if workStart after the site openingHours start, create a busyBlock from site opening time to workStart
                result.push({
                  start: moment(start).toDate(),
                  end: moment(workStart).toDate(),
                  employeeId: employee._id
                });
              }

              let endHour = customRoster.values.max - (customRoster.values.max % 1);
              let endMinute = 0;
              let fractionEnd = customRoster.values.max - endHour;
              if (fractionEnd === 0.25) {
                endMinute = 15;
              } else if (fractionEnd === 0.5) {
                endMinute = 30;
              } else if (fractionEnd === 0.75) {
                endMinute = 45;
              }

              const workEnd = moment(date).hour(customRoster.values.max).minute(endMinute).second(0).millisecond(0);
              if (workEnd.isBefore(end)) {
                // if workEnd before the site openingHours end, create a busyBlock from workEnd to site closing time
                result.push({
                  start: moment(workEnd).toDate(),
                  end: moment(end).toDate(),
                  employeeId: employee._id
                });
              }

              // check for lunch break, if there is, create a busyBlock for it
              if (Number(dayOfWeekRoster.break) !== 0) {
                let lunchHour = (roster.dailyBreak[2] === 'pm' && roster.dailyBreak[0] < 12) ? roster.dailyBreak[0] + 12 : roster.dailyBreak[0];
                let lunchStart = moment(date).hour(lunchHour).minute(roster.dailyBreak[1]).second(0).millisecond(0);
                let lunchEnd = moment(lunchStart).add(Number(dayOfWeekRoster.break), 'minutes');
                result.push({
                  start: moment(lunchStart).toDate(),
                  end: moment(lunchEnd).toDate(),
                  employeeId: employee._id
                });
              }
            }

            return;
          }
        }
        
        // if not working, create a busyBlock for the whole day
        if (dayOfWeekRoster.active === false) {
          result.push({
            start: moment(start).startOf('day').toDate(),
            end: moment(end).endOf('day').toDate(),
            employeeId: employee._id
          });
        } else {
          // working => check if working hours are different from site opening hours, if there is, create a busyBlock for it
          let startHour = dayOfWeekRoster.values.min - (dayOfWeekRoster.values.min % 1);
          let startMinute = 0;
          let fractionStart = dayOfWeekRoster.values.min - startHour;
          if (fractionStart === 0.25) {
            startMinute = 15;
          } else if (fractionStart === 0.5) {
            startMinute = 30;
          } else if (fractionStart === 0.75) {
            startMinute = 45;
          }

          const workStart = moment(date).hour(dayOfWeekRoster.values.min).minute(startMinute).second(0).millisecond(0);
          if (workStart.isAfter(start)) {
            // if workStart after the site openingHours start, create a busyBlock from site opening time to workStart
            result.push({
              start: moment(start).toDate(),
              end: moment(workStart).toDate(),
              employeeId: employee._id
            });
          }

          let endHour = dayOfWeekRoster.values.max - (dayOfWeekRoster.values.max % 1);
          let endMinute = 0;
          let fractionEnd = dayOfWeekRoster.values.max - endHour;
          if (fractionEnd === 0.25) {
            endMinute = 15;
          } else if (fractionEnd === 0.5) {
            endMinute = 30;
          } else if (fractionEnd === 0.75) {
            endMinute = 45;
          }

          const workEnd = moment(date).hour(dayOfWeekRoster.values.max).minute(endMinute).second(0).millisecond(0);
          if (workEnd.isBefore(end)) {
            // if workEnd before the site openingHours end, create a busyBlock from workEnd to site closing time
            result.push({
              start: moment(workEnd).toDate(),
              end: moment(end).toDate(),
              employeeId: employee._id
            });
          }

          // check for lunch break, if there is, create a busyBlock for it
          if (Number(dayOfWeekRoster.break) !== 0) {
            let lunchHour = (roster.dailyBreak[2] === 'pm' && roster.dailyBreak[0] < 12) ? roster.dailyBreak[0] + 12 : roster.dailyBreak[0];
            let lunchStart = moment(date).hour(lunchHour).minute(roster.dailyBreak[1]).second(0).millisecond(0);
            let lunchEnd = moment(lunchStart).add(Number(dayOfWeekRoster.break), 'minutes');
            result.push({
              start: moment(lunchStart).toDate(),
              end: moment(lunchEnd).toDate(),
              employeeId: employee._id
            });
          }
        }
      }
    });

    let morningSlots = [];
    let afternoonSlots = [];
    let eveningSlots = [];

    while (start.isBefore(end)) {
      // get time blocks for selected services at this "start"
      let bookingBlocks = [];
      let assumeStart = moment(start);
      selectedServices.forEach((service, index) => {
        const requiredResources = resources.filter(res => res.siteId === selectedSite._id && res.serviceIds.includes(service._id));

        service.timeSlots.forEach(timeSlot => {
          if (timeSlot.type === 'Busy') {
            bookingBlocks.push({
              start: moment(assumeStart).toDate(),
              end: moment(assumeStart).add(timeSlot.duration, 'minutes').toDate(),
              selectedEmployee: selectedEmployees[index],
              serviceId: service._id,
              resourceIds: requiredResources.map(obj => obj._id)
            });
          }
          assumeStart.add(timeSlot.duration, 'minutes');
        });
      });

      let notAvailable = false;
      let type = '';
      if (start.isBefore(moment())) {
        notAvailable = true;
        type = 'past';
      } else {
        if (start.diff(moment(), 'hours') < minimumBookingHours) {
          notAvailable = true;
          type = 'minimumHours';
        } else {
          // check if any blocks overlapped the busyBlocks result
          notAvailable = bookingBlocks.some(bookBlock => {
            if (bookBlock.selectedEmployee === 'any') {
              // any employees => filter overlapped blocks at this time
              // if the number of overlapped blocks >= the number of working employees
              // then no one is free at this time, thus it is not available
              const filterOverlappedBlocks = result.filter(busyBlock =>
                moment(bookBlock.start).isBetween(moment(busyBlock.start), moment(busyBlock.end), null, '[)') ||
                moment(bookBlock.end).isBetween(moment(busyBlock.start), moment(busyBlock.end), null, '(]') ||
                moment(busyBlock.start).isBetween(moment(bookBlock.start), moment(bookBlock.end), null, '[)') ||
                moment(busyBlock.end).isBetween(moment(bookBlock.start), moment(bookBlock.end), null, '(]')
              );

              return workingEmployees.filter(employee => employee.serviceSkills === undefined || employee.serviceSkills.includes(bookBlock.serviceId))
                .every(employee => filterOverlappedBlocks.find(block => block.employeeId === employee._id) !== undefined);
            } else {
              // specific employee => filter blocks of that employee
              // if there is a block overlap, then it is not available
              const filterEmployee = result.filter(busyBlock => busyBlock.employeeId === bookBlock.selectedEmployee);
              return filterEmployee.some(busyBlock =>
                moment(bookBlock.start).isBetween(moment(busyBlock.start), moment(busyBlock.end), null, '[)') ||
                moment(bookBlock.end).isBetween(moment(busyBlock.start), moment(busyBlock.end), null, '(]') ||
                moment(busyBlock.start).isBetween(moment(bookBlock.start), moment(bookBlock.end), null, '[)') ||
                moment(busyBlock.end).isBetween(moment(bookBlock.start), moment(bookBlock.end), null, '(]')
              );
            }
          });
        }
      }

      let isAllResourcesAvailable = true;
      if (!notAvailable) {
        // pass the busy timeslot check, now check for resources availability
        const bookingBlocksWithResources = bookingBlocks.filter(block => block.resourceIds.length > 0);
        isAllResourcesAvailable = bookingBlocksWithResources.every(block =>
          block.resourceIds.every(resId => checkResourceAvailability(resId, block, resources.find(r => r._id === resId).quantity, result))
        )
      }

      if (start.isBefore(moment(start).hour(12).minute(0))) {
        morningSlots.push({
          available: !notAvailable && isAllResourcesAvailable,
          start: moment(start),
          type: type
        });
      } else if (start.isBetween(moment(start).hour(11).minute(59).second(59), moment(start).hour(17).minute(0))) {
        afternoonSlots.push({
          available: !notAvailable && isAllResourcesAvailable,
          start: moment(start),
          type: type
        });
      } else {
        eveningSlots.push({
          available: !notAvailable && isAllResourcesAvailable,
          start: moment(start),
          type: type
        });
      }

      // start.add(15, 'minutes');
      start.add(timeSlotInterval, 'minutes');
    }

    if (reduceGaps) {
      // Only show 2 available slots before and after the busy blocks
      let allSlots = [...morningSlots, ...afternoonSlots, ...eveningSlots];

      // get the indexes of the original unavailables slots, then set available slots to unavailable
      if (allSlots.findIndex(slot => !slot.available && slot.type !== 'past') !== -1) {
        let originalUnavailableSlotsIndex = [];
        allSlots.forEach((slot, index) => {
          if (!slot.available) {
            originalUnavailableSlotsIndex.push(index)
          } else {
            allSlots[index].available = false;
          }
        });

        // go through each original index
        originalUnavailableSlotsIndex.forEach(originalIndex => {
          // checking the slots before and after the unvailable slots
          // if originally they are available => make it available again
          // 1 slot before
          if (originalIndex > 0) {
            if (!originalUnavailableSlotsIndex.includes(originalIndex - 1)) {
              allSlots[originalIndex - 1].available = true;
            }
          }

          // 2 slots before
          if (originalIndex > 1) {
            if (!originalUnavailableSlotsIndex.includes(originalIndex - 2)) {
              allSlots[originalIndex - 2].available = true;
            }
          }

          // 1 slot after
          if (allSlots[originalIndex + 1] !== undefined) {
            if (!originalUnavailableSlotsIndex.includes(originalIndex + 1)) {
              allSlots[originalIndex + 1].available = true;
            }
          }

          // 2 slots after
          if (allSlots[originalIndex + 2] !== undefined) {
            if (!originalUnavailableSlotsIndex.includes(originalIndex + 2)) {
              allSlots[originalIndex + 2].available = true;
            }
          }
        })
      }
    }

    morningSlots.forEach((slot, index) => {
      const isExceedCloseTime = moment(slot.start).add(expectedDuration, 'minutes').isAfter(moment(end).add(afterHoursMinute, 'minutes'));
      if (isExceedCloseTime) {
        morningSlots[index].available = false;
      }
    })

    afternoonSlots.forEach((slot, index) => {
      const isExceedCloseTime = moment(slot.start).add(expectedDuration, 'minutes').isAfter(moment(end).add(afterHoursMinute, 'minutes'));
      if (isExceedCloseTime) {
        afternoonSlots[index].available = false;
      }
    })

    eveningSlots.forEach((slot, index) => {
      const isExceedCloseTime = moment(slot.start).add(expectedDuration, 'minutes').isAfter(moment(end).add(afterHoursMinute, 'minutes'));
      if (isExceedCloseTime) {
        eveningSlots[index].available = false;
      }
    })

    if (type === 'day') {
      setSearchingTime(false);
      setSearchedTime(true);
      setSelectedTime(false);
      setSelectedDateTime(null);
      setMorning(morningSlots);
      setAfternoon(afternoonSlots);
      setEvening(eveningSlots);
      setBusyBlocks(result);
      setCountdownKey(countdownKey + 1);
      setCountdownTime(Date.now() + 600000);
      countdownRef.current.getApi().start();
    } else if (type === 'month') {
      const dateNotAvailable = morningSlots.filter(slot => slot.available).length + afternoonSlots.filter(slot => slot.available).length + eveningSlots.filter(slot => slot.available).length;
      if (dateNotAvailable === 0) {
        return false
      } else {
        return true
      }
    }
  }

  const checkResourceAvailability = (resourceId, block, resourceQuantity, eventsOnDate) => {
    const eventsWithResources = eventsOnDate.filter(ev =>
      ev.resourceIds !== undefined &&
      ev.resourceIds.includes(resourceId) &&
      moment(ev.start).isBefore(moment(block.end)) &&
      moment(ev.end).isAfter(moment(block.start))
    )

    if (eventsWithResources.length < resourceQuantity) {
      return true
    } else {
      return false
    }
  }

  const clickSearchTimes = () => {
    setSearchingTime(true);
    setSearchedTime(false);
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);

    axios.post('https://app.clientdiary.com/methods/getBusyTimes', [selectedSite, selectedDate])
    // axios.post('http://localhost:3000/methods/getBusyTimes', [selectedSite, selectedDate])
    .then(function (response) {
      const result = response.data;

      getAvailableSlots(result, selectedDate, 'day');
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const clickSearchClasses = () => {
    setSearchingTime(true);
    setSearchedTime(false);
    setSelectedTime(false);
    setSelectedDateTime(null);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);

    axios.post('https://app.clientdiary.com/methods/getClassTime', [selectedSite, selectedClass])
    // axios.post('http://localhost:3000/methods/getClassTime', [selectedSite, selectedClass])
    .then(function (response) {
      setSearchingTime(false);
      setSearchedTime(true);
      setSelectedTime(false);
      setSelectedDateTime(null);
      setAvailableClasses(response.data);
      setCountdownKey(countdownKey + 1);
      setCountdownTime(Date.now() + 600000);
      countdownRef.current.getApi().start();
    });
  }

  const handleSelectTime = (time) => {
    // calculate the final selected time and employees
    let selectedServicesNow = [...selectedServices];
    let employeeForServices = [];
    let start = moment(time);
    for (let i = 0; i < selectedServicesNow.length; i++) {
      for (let u = 0; u < selectedServicesNow[i].timeSlots.length; u++) {
        let end = moment(start).add(selectedServicesNow[i].timeSlots[u].duration, 'minutes');
        if (selectedServicesNow[i].timeSlots[u].type === 'Busy') {
          if (selectedEmployees[i] === 'any') {
            // if selectedEmployee is any, find an employee that doesn't have busyblock at this time
            const workingEmployees = employees.filter(employee =>
              employee.siteIds.includes(selectedSite._id) &&
              employee.siteIdsOnlineBooking.includes(selectedSite._id) &&
              (employee.serviceSkills === undefined || employee.serviceSkills.includes(selectedServicesNow[i]._id))
            );
            for (let e = 0; e < workingEmployees.length; e++) {
              // filter blocks of the employee, if there is a block overlap, then it is not available
              const filterEmployee = busyBlocks.filter(busyBlock => busyBlock.employeeId === workingEmployees[e]._id);
              const notAvailable = filterEmployee.some(busyBlock =>
                moment(start).isBetween(moment(busyBlock.start), moment(busyBlock.end), null, '[)') ||
                moment(end).isBetween(moment(busyBlock.start), moment(busyBlock.end), null, '(]') ||
                moment(busyBlock.start).isBetween(moment(start), moment(end), null, '[)') ||
                moment(busyBlock.end).isBetween(moment(start), moment(end), null, '(]')
              );

              if (notAvailable === false) {
                if (selectedServicesNow[i].priceInBundle !== undefined) {
                  selectedServicesNow[i].price = selectedServicesNow[i].priceInBundle;
                } else {
                  let price = 0;

                  if (workingEmployees[e].serviceTierId !== '') {
                    if (selectedServicesNow[i].tierPrices !== undefined && selectedServicesNow[i].tierPrices.find(x => x.tierId === workingEmployees[e].serviceTierId && x.siteId === selectedSite._id) !== undefined) {
                      price = Number(selectedServicesNow[i].tierPrices.find(x => x.tierId === workingEmployees[e].serviceTierId && x.siteId === selectedSite._id).price);
                    }
                  }

                  if (price === 0) {
                    const priceList = selectedServicesNow[i].pricesList.find(priceList => priceList.siteId === selectedSite._id);
                    price = priceList.price / 100;
                  }

                  selectedServicesNow[i].price = price;
                }

                employeeForServices.push({
                  serviceOrder: i,
                  serviceBlock: u,
                  employeeId: workingEmployees[e]._id,
                  requested: false
                });

                break;
              }
            }
          } else {
            // specific employee
            const specificEmployee = employees.find(employee => employee._id === selectedEmployees[i]);

            if (selectedServicesNow[i].priceInBundle !== undefined) {
              selectedServicesNow[i].price = selectedServicesNow[i].priceInBundle;
            } else {
              let price = 0;
              if (specificEmployee.serviceTierId !== '') {
                if (selectedServicesNow[i].tierPrices !== undefined && selectedServicesNow[i].tierPrices.find(x => x.tierId === specificEmployee.serviceTierId && x.siteId === selectedSite._id) !== undefined) {
                  price = Number(selectedServicesNow[i].tierPrices.find(x => x.tierId === specificEmployee.serviceTierId && x.siteId === selectedSite._id).price);
                }
              }

              if (price === 0) {
                const priceList = selectedServicesNow[i].pricesList.find(priceList => priceList.siteId === selectedSite._id);
                price = priceList.price / 100;
              }

              selectedServicesNow[i].price = price;
            }

            employeeForServices.push({
              serviceOrder: i,
              serviceBlock: u,
              employeeId: selectedEmployees[i],
              requested: true
            });
          }
        }

        start = end;
      }
    }

    let amountToPayNow = 0;
    selectedServicesNow.forEach(service => {
      if (prePaymentType === 'percentage') {
        amountToPayNow += Number(service.price) * prePaymentAmount / 100;
      } else if (prePaymentType === 'value') {
        amountToPayNow += Number(service.price)
      }
    });

    setSelectedTime(true);
    setSelectedDateTime(time);
    setEmployeeForServices(employeeForServices);
    setSelectedServices(selectedServicesNow);
    setAmountToPay(amountToPayNow * 100);

    handleNext();
  }

  const clickPayWithCard = () => {
    if (firstname.trim() === '') {
      setFirstnameError('First name is required');
      return;
    }

    if (lastname.trim() === '') {
      setLastnameError('Last name is required');
      return;
    }

    if (email.trim() === '') {
      setEmailError('Email is required');
      return;
    }

    if (phone.trim() === '') {
      setPhoneError('Mobile number is required');
      return;
    }

    const clientInfo = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      email: email.trim(),
      phone: phone.trim()
    };

    setSubmitting(true);

    const dateString = moment(selectedDateTime).format('dddd, Do MMMM YYYY');
    const timeString = moment(selectedDateTime).format('h:mm A');

    if (selectedCalendarEvent === null) {
      if (selectedDateTime === null) {
        alert('You have not selected date and time for you appointment. Please check.')
      } else {
        // appointment
        axios.post('https://preview.clientdiary.com/methods/onlineBookingAppointmentNew',[clientInfo, selectedSite, selectedServices, employeeForServices, selectedDateTime, note.trim(), dateString, timeString, true])
        // axios.post('http://localhost:3000/methods/onlineBookingAppointmentNew',[clientInfo, selectedSite, selectedServices, employeeForServices, selectedDateTime, note.trim(), dateString, timeString, true])
        .then(async function (response) {
          const appointmentId = response.data;

          let amountToPayNow = Number(amountToPay.toFixed(2));
          if (prePaymentType === 'value') {
            if (amountToPayNow >= (Number(prePaymentAmount) * 100)) {
              amountToPayNow = Number(prePaymentAmount) * 100
            }
          }

          const stripePromise = loadStripe('pk_live_d7KO18UnRkBTgKHDqZt67xcY', {
            stripeAccount: stripeConnectedId
          });

          // const stripePromise = loadStripe('pk_test_XkbNpvXMxV5Q5OKVYFgQMhHS', {
          //   stripeAccount: stripeConnectedId
          // });

          const stripe = await stripePromise;

          axios.post('https://app.clientdiary.com/methods/createCheckoutSession', [appointmentId, clientInfo, dateString, timeString, onlineBookingsUrl, Math.ceil(amountToPayNow), checkoutCurrency, selectedSite, addFees])
          // axios.post('http://localhost:3000/methods/createCheckoutSession', [appointmentId, clientInfo, dateString, timeString, onlineBookingsUrl, amountToPayNow, checkoutCurrency, selectedSite, addFees])
          .then(async function (response1) {
            const session = response1.data;

            const checkout = await stripe.redirectToCheckout({
              sessionId: session.id,
            });

            if (checkout.error) {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
              alert(checkout.error.message)
            }
          })
          .catch(function (error) {
            console.log(error);
            setSubmitting(false);
            setSubmitted(true);
            setSuccess(false);
            axios.post('https://app.clientdiary.com/methods/informSalon', [clientInfo, dateString, timeString, selectedSite]);
          });
        })
        .catch(function (error) {
          console.log(error);
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(false);
          axios.post('https://app.clientdiary.com/methods/informSalon', [clientInfo, dateString, timeString, selectedSite]);
        });
      }
    } else {
      // class
      axios.post('https://app.clientdiary.com/methods/onlineBookingClass',[clientInfo, selectedSite, selectedCalendarEvent, note.trim(), moment(selectedCalendarEvent.start).format('DD/MM/YYYY'), moment(selectedCalendarEvent.start).format('h:mm A'), true])
      // axios.post('http://localhost:3000/methods/onlineBookingClass',[clientInfo, selectedSite, selectedCalendarEvent, note.trim(), moment(selectedCalendarEvent.start).format('DD/MM/YYYY'), moment(selectedCalendarEvent.start).format('h:mm A'), true])
      .then(async function (response) {
        const calendarEventId = response.data.calendarEventId;
        const clientId = response.data.clientId;

        let amountToPayNow = Number(amountToPay.toFixed(2));
        if (prePaymentType === 'value') {
          if (amountToPayNow >= (Number(prePaymentAmount) * 100)) {
            amountToPayNow = Number(prePaymentAmount) * 100
          }
        }

        const stripePromise = loadStripe('pk_live_d7KO18UnRkBTgKHDqZt67xcY', {
          stripeAccount: stripeConnectedId
        });

        // const stripePromise = loadStripe('pk_test_XkbNpvXMxV5Q5OKVYFgQMhHS', {
        //   stripeAccount: stripeConnectedId
        // });

        const stripe = await stripePromise;

        axios.post('https://app.clientdiary.com/methods/createCheckoutSessionClass', [calendarEventId, clientInfo, clientId, dateString, timeString, onlineBookingsUrl, Math.ceil(amountToPayNow), checkoutCurrency, selectedSite, addFees])
        // axios.post('http://localhost:3000/methods/createCheckoutSessionClass', [calendarEventId, clientInfo, clientId, dateString, timeString, onlineBookingsUrl, amountToPayNow, checkoutCurrency, selectedSite, addFees])
        .then(async function (response1) {
          const session = response1.data;

          const checkout = await stripe.redirectToCheckout({
            sessionId: session.id,
          });

          if (checkout.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            alert(checkout.error.message)
          }
        })
        .catch(function (error) {
          console.log(error);
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(false);
          axios.post('https://app.clientdiary.com/methods/informSalon', [clientInfo, dateString, timeString, selectedSite]);
        });
      })
      .catch(function (error) {
        console.log(error);
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(false);
        axios.post('https://app.clientdiary.com/methods/informSalon', [clientInfo, dateString, timeString, selectedSite]);
      });
    }
  }

  const checkRequirements = () => {
    if (firstname.trim() === '') {
      setFirstnameError('First name is required');
      return;
    }

    if (lastname.trim() === '') {
      setLastnameError('Last name is required');
      return;
    }

    if (email.trim() === '') {
      setEmailError('Email is required');
      return;
    }

    if (phone.trim() === '') {
      setPhoneError('Mobile number is required');
      return;
    }

    const clientInfo = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      email: email.trim(),
      phone: phone.trim()
    };

    setSubmitting(true);

    const dateString = moment(selectedDateTime).format('dddd, Do MMMM YYYY');
    const timeString = moment(selectedDateTime).format('h:mm A');

    let totalPrice = 0;
    selectedServices.forEach(service => totalPrice += Number(service.price));

    axios.post('https://app.clientdiary.com/methods/checkClientForPrepayment',[conditionalPrepayment, clientInfo, totalPrice, selectedSite])
    .then(function (response) {
      if (response.data.prePaymentNeeded) {
        setSubmitting(false);
        setRequirePrePayment(true);
      } else {
        clickBookAppointmentNew(false);
      }
    })
    .catch(function (error) {
      console.log(error);
      setSubmitting(false);
      setSubmitted(true);
      setSuccess(false);
      axios.post('https://app.clientdiary.com/methods/informSalon', [clientInfo, dateString, timeString, selectedSite]);
    });
  }

  const clickBookAppointmentNew = (isDepositRequired) => {
    if (isDepositRequired === false) {
      if (firstname.trim() === '') {
        setFirstnameError('First name is required');
        return;
      }

      if (lastname.trim() === '') {
        setLastnameError('Last name is required');
        return;
      }

      if (email.trim() === '') {
        setEmailError('Email is required');
        return;
      }

      if (phone.trim() === '') {
        setPhoneError('Mobile number is required');
        return;
      }
    }

    const clientInfo = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      email: email.trim(),
      phone: phone.trim()
    };

    setSubmitting(true);

    const dateString = moment(selectedDateTime).format('dddd, Do MMMM YYYY');
    const timeString = moment(selectedDateTime).format('h:mm A');

    if (selectedCalendarEvent === null) {
      // appointment
      axios.post('https://preview.clientdiary.com/methods/onlineBookingAppointmentNew', [clientInfo, selectedSite, selectedServices, employeeForServices, selectedDateTime, note.trim(), dateString, timeString, isDepositRequired])
      .then(function (response) {
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(false);
        axios.post('https://app.clientdiary.com/methods/informSalon', [clientInfo, dateString, timeString, selectedSite]);
      });
    } else {
      // class
      axios.post('https://app.clientdiary.com/methods/onlineBookingClass', [clientInfo, selectedSite, selectedCalendarEvent, note.trim(), moment(selectedCalendarEvent.start).format('DD/MM/YYYY'), moment(selectedCalendarEvent.start).format('h:mm A'), isDepositRequired])
      // axios.post('http://localhost:3000/methods/onlineBookingClass',[clientInfo, selectedSite, selectedCalendarEvent, note.trim(), moment(selectedCalendarEvent.start).format('DD/MM/YYYY'), moment(selectedCalendarEvent.start).format('h:mm A'), isDepositRequired])
      .then(function (response) {
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(false);
        axios.post('https://app.clientdiary.com/methods/informSalon', [clientInfo, dateString, timeString, selectedSite]);
      });
    }
  }

  const onCountdownComplete = () => {
    setSearchedTime(false);
    setSelectedTime(false);
    setMorning([]);
    setAfternoon([]);
    setEvening([]);
  }

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Typography variant="subtitle2">Time's up! Please click <strong>Search available times</strong> again.</Typography>
    } else {
      // Render a countdown
      return <Typography variant="subtitle2">Time remaining: {minutes}:{seconds}</Typography>;
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        // Select Location
        return (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableBody>
                {renderLocationsRows()}
              </TableBody>
            </Table>
          </TableContainer>
        )
      case 1:
        // Select Services
        return (
          <Stack spacing={2}>
            <Paper variant="outlined">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, value) => setTabValue(value)}>
                      <Tab label="Services" value="services" />
                      <Tab label="Bundles" value="bundles" />
                      <Tab label="Classes" value="classes" />
                    </TabList>
                  </Box>
                  <TabPanel value="services" sx={{ p: 0 }}>{activeStep === 1 && renderServices()}</TabPanel>
                  <TabPanel value="bundles" sx={{ p: 0 }}>{activeStep === 1 && renderBundlesList()}</TabPanel>
                  <TabPanel value="classes" sx={{ p: 0 }}>{activeStep === 1 && renderClassesList()}</TabPanel>
                </TabContext>
              </Box>
            </Paper>
          </Stack>
        )
      case 2:
        // Select employees
        if (selectedClass.length !== 0) {
          // Class => No staff picking
          return null
        } else {
          // Services => Can pick staff
          return (
            <Paper variant="outlined" sx={{ borderTop: 0 }}>{renderSelectStaff()}</Paper>
          )
        }
      case 3:
        // Select date and time
        return (
          <>
            {selectedClass.length !== 0 ?
              <Stack spacing={2}>
                <Button variant="contained" color="primary" onClick={clickSearchClasses} style={{ marginBottom: 10 }}>
                  Search available classes
                </Button>
                <Countdown
                  ref={countdownRef}
                  key={countdownKey}
                  date={countdownTime}
                  autoStart={false}
                  renderer={rendererCountdown}
                  onComplete={onCountdownComplete}
                />
                <div>
                  {searchingTime && <CircularProgress />}
                  {renderAvailableClasses()}
                </div>
              </Stack>
              :
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                  <Paper elevation={3}>
                    <DateCalendar
                      sx={{ width: 'auto' }}
                      // displayStaticWrapperAs="desktop"
                      openTo="day"
                      orientation="portrait"
                      minDate={moment().add(minimumBookingDays, 'days')}
                      maxDate={moment().add(maximumBookingDays, 'days')}
                      shouldDisableDate={(date) => closedDay.includes(date.day())}
                      value={moment(selectedDate)}
                      onChange={handleSelectDate}
                      // renderInput={(params) => <TextField {...params} />}
                      loading={isDatePickerLoading}
                      onMonthChange={handleMonthChange}
                      renderLoading={() => <DayCalendarSkeleton />}
                      slots={{ day: CustomDay }}
                      slotProps={{
                        day: { highlightedDays: highlightedDays },
                      }}
                      // renderDay={(day, _value, DayComponentProps) => {
                      //   const invisible =
                      //     DayComponentProps.outsideCurrentMonth ||
                      //     DayComponentProps.disabled

                      //   const isSelected =
                      //     !DayComponentProps.outsideCurrentMonth &&
                      //     !DayComponentProps.disabled &&
                      //     // (highlightedDays.length === 0 || highlightedDays.indexOf(day.date()) !== -1)
                      //     highlightedDays.indexOf(day.date()) !== -1

                      //   if (isSelected) {
                      //     return (
                      //       <Badge
                      //         key={day.toString()}
                      //         overlap="circular"
                      //         color={isSelected ? "success" : "error"}
                      //         variant="dot"
                      //         invisible={invisible}
                      //       >
                      //         <PickersDay {...DayComponentProps} />
                      //       </Badge>
                      //     );
                      //   } else {
                      //     return (
                      //       <Badge
                      //         key={day.toString()}
                      //         overlap="circular"
                      //         badgeContent={<span style={{ fontSize: 8 }}>❌</span>}
                      //         invisible={invisible}
                      //       >
                      //         <PickersDay {...DayComponentProps} />
                      //       </Badge>
                      //     );
                      //   }
                      // }}
                    />
                  </Paper>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 8 }}>
                  <Stack spacing={2}>
                    <Button variant="contained" color="primary" onClick={clickSearchTimes}>
                      Search available times
                    </Button>
                    <Countdown
                      ref={countdownRef}
                      key={countdownKey}
                      date={countdownTime}
                      autoStart={false}
                      renderer={rendererCountdown}
                      onComplete={onCountdownComplete}
                    />
                    {searchingTime && <CircularProgress />}
                    {renderTimeSlots()}
                  </Stack>
                </Grid>
              </Grid>
            }
          </>
        )
      case 4:
        // Summary and Payment
        if (selectedSite === null) {
          return null
        } else {
          return (
            <Grid container spacing={2} justifyContent="center">
              <Grid size={{ xs: 12, md: 4, lg: 4 }}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <List dense sx={{ pt: 0 }}>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="ci:location" width="32" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Location"
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondary={selectedSite.name}
                        secondaryTypographyProps={{ variant: 'subtitle2' }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Iconify icon="healthicons:i-schedule-school-date-time" width="32" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Date & Time"
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondary={selectedCalendarEvent === null ? moment(selectedDateTime).format('DD/MM/YYYY h:mm A') : moment(selectedCalendarEvent.start).format('DD/MM/YYYY h:mm A')}
                        secondaryTypographyProps={{ variant: 'subtitle2' }}
                      />
                    </ListItem>
                    { selectedCalendarEvent === null ?
                      <>
                        <ListItemButton onClick={() => setSummaryCollapse(!summaryCollapse)}>
                          <ListItemIcon style={{ width: 32 }}>
                            <Iconify icon="la:handshake-solid" width="32" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Services"
                            primaryTypographyProps={{ variant: 'body2' }}
                            secondary={selectedServices.length + (selectedServices.length === 1 ? ' service selected' : ' services selected')}
                            secondaryTypographyProps={{ variant: 'subtitle2' }}
                          />
                          {summaryCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        <Collapse in={summaryCollapse} timeout="auto" unmountOnExit>
                          <List dense>
                            {renderSelectedServicesSummary()}
                          </List>
                        </Collapse>
                      </>
                      :
                      <ListItem>
                        <ListItemIcon>
                          <Iconify icon="la:handshake-solid" width="32" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Class"
                          primaryTypographyProps={{ variant: 'body2' }}
                          secondary={selectedCalendarEvent.selectedClass.className}
                          secondaryTypographyProps={{ variant: 'subtitle2' }}
                        />
                        {currency} {Number(selectedCalendarEvent.price / 100).toFixed(2)}
                      </ListItem>
                    }
                  </List>
                  <Typography variant="subtitle2"><i>Expected Duration: {expectedDuration} minutes.</i></Typography>
                  <Typography variant="caption"><i>Disclaimer: Pricing and timing are subjected to changes.</i></Typography>
                </Paper>
              </Grid>
              <Grid size={{ xs: 12, md: 4, lg: 4 }}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Stack spacing={2} alignItems="center">
                    <Typography variant="h6">Your Details</Typography>
                    <TextField
                      margin="normal"
                      size="small"
                      label={firstnameError === '' ? "First Name (*Required)" : firstnameError}
                      value={firstname}
                      error={firstnameError === '' ? false : true}
                      onChange={(e) => { setFirstname(e.target.value); setFirstnameError(''); }}
                      style={{ minWidth: 250 }}
                    />
                    <TextField
                      margin="normal"
                      size="small"
                      label={lastnameError === '' ? "Last Name (*Required)" : lastnameError}
                      value={lastname}
                      error={lastnameError === '' ? false : true}
                      onChange={(e) => { setLastname(e.target.value); setLastnameError(''); }}
                      style={{ minWidth: 250 }}
                    />
                    <TextField
                      margin="normal"
                      size="small"
                      label={emailError === '' ? "Email Address (*Required)" : emailError}
                      value={email}
                      error={emailError === '' ? false : true}
                      onChange={(e) => { setEmail(e.target.value); setEmailError(''); }}
                      style={{ minWidth: 250 }}
                    />
                    <TextField
                      margin="normal"
                      size="small"
                      label={phoneError === '' ? "Mobile Number (*Required)" : phoneError}
                      value={phone}
                      error={phoneError === '' ? false : true}
                      onChange={(e) => { setPhone(e.target.value); setPhoneError(''); }}
                      type="number"
                      style={{ minWidth: 250 }}
                    />
                    <TextField
                      margin="normal"
                      size="small"
                      label="Note (Optional)"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      style={{ minWidth: 250 }}
                    />
                    {(termsAndConditions !== undefined || termsAndConditions !== '') &&
                      <Stack alignItems="center">
                        <Typography variant="caption" component="div">
                          By booking an appointment, you agree to our
                        </Typography>
                        <Link component="button" onClick={() => setTermsDialogOpen(true)}><b><i>Terms & Conditions</i></b></Link>
                      </Stack>
                    }
                    <Stack alignItems="center" spacing={1}>
                      {prePayment && amountToPay !== 0 ?
                        <>
                          {conditionalPrepayment === undefined || (conditionalPrepayment.newClientChecked === false && conditionalPrepayment.totalValueChecked === false && conditionalPrepayment.noShowChecked === false) ?
                            <>
                              <Typography variant="caption"><i>Disclaimer: {businessName} requires a {prePaymentType === 'value' ? (currency + prePaymentAmount) : (prePaymentAmount + '%')} booking fee for online bookings.</i></Typography>
                              <Typography variant="caption"><i>Please continue with the button below.</i></Typography>
                              <Button variant="contained" color="primary" disabled={submitting ? true : false} onClick={clickPayWithCard}>
                                {submitting ? <CircularProgress /> : "Book and Pay Booking Fee"}
                              </Button>
                            </>
                            :
                            <>
                              {requirePrePayment && amountToPay !== 0 ?
                                <>
                                  <Typography variant="caption"><i>Disclaimer: {businessName} requires a {prePaymentType === 'value' ? (currency + prePaymentAmount) : (prePaymentAmount + '%')} booking fee for online bookings.</i></Typography>
                                  <Typography variant="caption"><i>Please continue with the button below.</i></Typography>
                                  <Button variant="contained" color="primary" disabled={submitting ? true : false} onClick={clickPayWithCard}>
                                    {submitting ? <CircularProgress /> : "Book and Pay Booking Fee"}
                                  </Button>
                                </>
                                :
                                <Button variant="contained" color="primary" disabled={submitting ? true : false} onClick={checkRequirements}>
                                  {submitting ? <CircularProgress /> : "Book appointment"}
                                </Button>
                              }
                            </>
                          }
                        </>
                        :
                        <Button variant="contained" color="primary" disabled={submitting ? true : false} onClick={() => clickBookAppointmentNew(false)}>
                          {submitting ? <CircularProgress /> : "Book appointment"}
                        </Button>
                      }
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          )
        }
    }
  }

  const getOptionalText = (stepIndex) => {
    if (stepIndex === 0) {
      return <Typography variant="caption">{selectedSite !== null && selectedSite.name}</Typography>
    } else if (stepIndex === 1) {
      let optionalText = '';
      if (selectedServices.length !== 0) {
        selectedServices.forEach((service, index) => {
          optionalText += (index === selectedServices.length -1 ? service.name : (service.name + ', '));
        })
      }

      if (selectedClass.length !== 0) {
        optionalText = selectedClass[0].className;
      }

      return <Typography variant="caption">{optionalText}</Typography>
    } else if (stepIndex === 2) {
      // let optionalText = '';
      // selectedEmployees.forEach((employee, index) => {
      //   let employeeName = employee === 'any' ? 'Anyone' : employees.find(e => e._id === employee).firstName;
      //   optionalText += (index === selectedEmployees.length -1 ? employeeName : (employeeName + ', '));
      // });

      // return <Typography variant="caption">{optionalText}</Typography>
    } else if (stepIndex === 3) {
      if (selectedCalendarEvent === null) {
        // Services
        return <Typography variant="caption">{selectedTime === true && moment(selectedDateTime).format('DD/MM/YYYY h:mm A')}</Typography>
      } else {
        // Class
        return <Typography variant="caption">{moment(selectedCalendarEvent.start).format('DD/MM/YYYY h:mm A')}</Typography>
      }
    }
  }

  const renderContentV2 = () => {
    if (!linkValid) {
      return (
        <Card variant="outlined" style={{ margin: 10 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Online Booking link is incorrect.</Typography>
            <br />
            <Typography variant="body2" gutterBottom>Please contact the business and double check the online booking link.</Typography>
            <br />
            <Typography variant="body2">Thank you,</Typography>
            <Typography variant="body2"><strong><i>Online Booking System</i></strong></Typography>
          </CardContent>
        </Card>
      )
    } else {
      if (submitted) {
        // TO DO
        return (
          <Card variant="outlined" style={{ margin: 10 }}>
            { success ?
              <CardContent>
                <Typography variant="h5" gutterBottom>Thank you for using online booking.</Typography>
                <Typography variant="h6" gutterBottom>What's next?</Typography>
                <Typography variant="body1" gutterBottom>♦ You may receive an SMS to verify your mobile number. Please remember to reply YES to make your appointment valid.</Typography>
                <Typography variant="body1" gutterBottom>♦ After replying YES, we will review your booking details and be in contact as soon as possible to confirm your appointment.</Typography>
                <br />
                <Typography variant="body2">Thank you and we look forward to seeing you.</Typography>
                <br />
                <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
                <Typography variant="body2"><strong><i>{selectedSite.name}</i></strong></Typography>
                <Typography variant="body2"><strong><i>{selectedSite.address}</i></strong></Typography>
                <Typography variant="body2"><strong><i>{selectedSite.phone}</i></strong></Typography>
                <br />
                <Typography variant="subtitle2"><i>You can now safely close this page.</i></Typography>
                <br />
                <Button variant="contained" color="primary" onClick={() => {
                  const urlParams = new URLSearchParams(window.location.search);
                  window.location = 'https://booking.clientdiary.com/?bookingsUrl=' + urlParams.get('bookingsUrl');
                }}>
                  Book another appointment
                </Button>
              </CardContent>
              :
              <CardContent>
                <Typography variant="h5" gutterBottom>Thank you for using online booking.</Typography>
                <Typography variant="h6" gutterBottom style={{ color: 'red' }}>
                  <strong>
                    { isCancel ?
                      'You have canceled your booking fee payment.'
                      :
                      'Unfortunately, an error has occured.'
                    }
                  </strong>
                </Typography>
                <Typography variant="body1" gutterBottom>♦ Please contact us and we will help you with your bookings.</Typography>
                <br />
                <Typography variant="body2">Thank you and we look forward to hearing from you.</Typography>
                <br />
                <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
                <Typography variant="body2"><strong><i>{selectedSite.name}</i></strong></Typography>
                <Typography variant="body2"><strong><i>{selectedSite.address}</i></strong></Typography>
                <Typography variant="body2"><strong><i>{selectedSite.phone}</i></strong></Typography>
                <br />
                <Typography variant="subtitle2"><i>You can now safely close this page.</i></Typography>
                <br />
                <Button variant="contained" color="primary" onClick={() => {
                  const urlParams = new URLSearchParams(window.location.search);
                  window.location = 'https://booking.clientdiary.com/?bookingsUrl=' + urlParams.get('bookingsUrl');
                }}>
                  Book another appointment
                </Button>
              </CardContent>
            }
          </Card>
        )
      } else {
        return (
          <>
            {(onlineVoucherEnabled || (onlineBookingAnnouncementToggle && onlineBookingAnnouncement !== '')) &&
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                {onlineVoucherEnabled &&
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    href={"https://voucher.clientdiary.com/?business=" + onlineVoucherUrl}
                    endIcon={<OpenInNewIcon />}
                  >
                    Buy Vouchers / Gift Cards
                  </Button>
                }
                {(onlineBookingAnnouncementToggle && onlineBookingAnnouncement !== '') &&
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setAnnouncementDialogOpen(true)}
                      startIcon={<Iconify icon="emojione-v1:bull-horn-with-sound-waves" className="animate__animated animate__tada animate__infinite" width="36" />}
                    >
                      Announcement
                    </Button>
                    <Dialog
                      open={announcementDialogOpen}
                      disableEscapeKeyDown
                      maxWidth={false}
                    >
                      <DialogContent>
                        <div dangerouslySetInnerHTML={{ __html: onlineBookingAnnouncement }} />
                      </DialogContent>
                      <DialogActions>
                        <Button color="error" onClick={() => setAnnouncementDialogOpen(false)}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                }
              </Stack>
            }

            <Paper variant="outlined" sx={{ p: 1, width: '100%' }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel optional={getOptionalText(index)}>{step.label}</StepLabel>
                    <StepContent>
                      <Typography gutterBottom>{(activeStep === 2 || activeStep === 3) && selectedClass.length !== 0 ? step.descriptionClass : step.description}</Typography>
                      {getStepContent(index)}
                      <Box sx={{ mb: 2, mt: 2 }}>
                        {activeStep === 0 && selectedSite === null && <Typography variant="subtitle2" sx={{ mb: 1 }}>Please select location to continue.</Typography>}
                        {activeStep === 1 && selectedServices.length === 0 && selectedClass.length === 0 && <Typography variant="subtitle2" sx={{ mb: 1 }}>Please select at least one service for your appointment to continue.</Typography>}
                        {activeStep === 3 && selectedTime === false && selectedCalendarEvent === null && <Typography variant="subtitle2" sx={{ mb: 1 }}>Please select appointment date and time to continue.</Typography>}
                        <div>
                          {index !== steps.length - 1 &&
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                              disabled={
                                activeStep === 0 && selectedSite === null ? true :
                                activeStep === 1 && selectedServices.length === 0 && selectedClass.length === 0 ? true :
                                activeStep === 3 && (selectedTime === false || selectedDateTime === null) && selectedCalendarEvent === null ? true : false
                              }
                            >
                              {activeStep === 0 && 'Next Step: Services'}
                              {activeStep === 1 &&
                                ((employees.filter(employee => employee.siteIds.includes(selectedSite._id) && employee.siteIdsOnlineBooking.includes(selectedSite._id)).length === 1 && 'Next Step: Date & Time')
                                ||
                                (employees.filter(employee => employee.siteIds.includes(selectedSite._id) && employee.siteIdsOnlineBooking.includes(selectedSite._id)).length !== 1 && 'Next Step: Staff'))
                              }
                              {activeStep === 2 && 'Next Step: Date & Time'}
                              {activeStep === 3 && 'Next Step: Summary & Confirm'}
                            </Button>
                          }
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                            color="error"
                            variant="outlined"
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Paper>

            {(selectedServices.length !== 0 || selectedClass.length !== 0) &&
              <SwipeableEdgeDrawer
                selectedServices={selectedServices}
                selectedClass={selectedClass}
                expectedDuration={expectedDuration}
                removeSelectedServiceBundle={removeSelectedServiceBundle}
                removeSelectedService={removeSelectedService}
                removeSelectedClass={removeSelectedClass}
                handleClickNextStep={handleNext}
                activeStep={activeStep}
              />
            }
          </>
        )
      }
    }
  }

  const renderSelectedServicesSummary = () => {
    return selectedServices.map((service, index) => {
      return (
        <ListItem key={index} sx={{ pl: 4 }}>
          <ListItemText primary={service.onlineBookingName.trim() !== '' ? service.onlineBookingName : service.name} />
          <Typography variant="subtitle2">{currency} {Number(service.price).toFixed(2)}</Typography>
        </ListItem>
      )
    })
  }

  const handleSelectCalendarEvent = (calendarEvent) => {
    let amountToPayNow = 0;
    if (prePaymentType === 'percentage') {
      amountToPayNow += Number(calendarEvent.price) * prePaymentAmount / 100;
    } else if (prePaymentType === 'value') {
      amountToPayNow += Number(calendarEvent.price)
    }

    setSelectedCalendarEvent(calendarEvent);
    setAmountToPay(amountToPayNow);
  }

  const renderAvailableClasses = () => {
    if (searchedTime && availableClasses.length === 0) {
      return (
        <Box>
          <Typography variant="body2" gutterBottom>This class doesn't have any scheduled date.</Typography>
          <Typography variant="body2" gutterBottom>Please try another class.</Typography>
          <Typography variant="body2" gutterBottom>Otherwise, please call {selectedSite.phone} for more information. Thank you.</Typography>
        </Box>
      )
    } else {
      return availableClasses.map((calendarEvent, index) => {
        const availableSlots = calendarEvent.selectedClass.classCapacity - calendarEvent.clients.filter(c => c.status !== 'Awaiting Deposit' && c.status !== 'Failed Deposit').length;
        const disabled = availableSlots > 0 ? false : true;
        let backgroundColor = 'white';
        if (disabled) {
          backgroundColor = 'transparent';
        } else if (selectedCalendarEvent !== null && calendarEvent._id === selectedCalendarEvent._id) {
          backgroundColor = 'rgb(76, 217, 100)';
        }

        return (
          <Button
            key={index}
            variant="outlined"
            disabled={disabled}
            onClick={() => handleSelectCalendarEvent(calendarEvent)}
            style={{ margin: 5, backgroundColor: backgroundColor }}
          >
            {moment(calendarEvent.start).format('ddd DD/MM/YYYY h:mm')} to {moment(calendarEvent.end).format('h:mm')} ({availableSlots} left!)
          </Button>
        )
      })
    }
  }

  const renderTimeSlots = () => {
    if (selectedSite === null) {
      return null
    } else {
      // get opening hours of the selected date
      const dayOfWeek = moment(selectedDate).day();
      const openingHours = selectedSite.openingHours[dayOfWeek].values;

      const end = moment(selectedDate).hour(openingHours.max).minute(openingHours.max % 1 === 0 ? 0 : 30).second(0).millisecond(0);

      let dateNotAvailable = morning.filter(slot => slot.available).length + afternoon.filter(slot => slot.available).length + evening.filter(slot => slot.available).length;

      return (
        <>
          {searchedTime && dateNotAvailable === 0 ?
            <Box>
              <Typography variant="body2" gutterBottom>There are no bookings available on this date.</Typography>
              <Typography variant="body2" gutterBottom>Please try another date or different staff and try again.</Typography>
              <Typography variant="body2" gutterBottom>Otherwise, please call {selectedSite.phone} and we can help you with an appointment. Thank you.</Typography>
            </Box>
            :
            <>
              {morning.length !== 0 &&
                <Box>
                  <Typography variant="h6">Morning</Typography>
                  {renderMorningSlots(end)}
                </Box>
              }
              {afternoon.length !== 0 &&
                <Box>
                  <Typography variant="h6">Afternoon</Typography>
                  {renderAfternoonSlots(end)}
                </Box>
              }
              {evening.length !== 0 &&
                <Box>
                  <Typography variant="h6">Evening</Typography>
                  {renderEveningSlots(end)}
                </Box>
              }
            </>
          }
        </>
      )
    }
  }

  const renderMorningSlots = (closingTime) => {
    let morningSlots = morning;
    if (allowHalfHourOnly) {
      morningSlots = morning.filter(slot => slot.start.minute() !== 15 && slot.start.minute() !== 45);
    }

    return morningSlots.map((slot, index) => {
      const isExceedCloseTime = moment(slot.start).add(expectedDuration, 'minutes').isAfter(moment(closingTime).add(afterHoursMinute, 'minutes'));
      const disabled = slot.available ? isExceedCloseTime : true;
      let backgroundColor = 'white';
      if (disabled) {
        backgroundColor = 'transparent';
      } else if (moment(slot.start).isSame(moment(selectedDateTime))) {
        backgroundColor = 'rgb(76, 217, 100)';
      }

      return (
        <Button
          key={index}
          variant="outlined"
          disabled={disabled}
          onClick={() => handleSelectTime(moment(slot.start).toDate())}
          style={{ margin: 5, backgroundColor: backgroundColor }}
        >
          {slot.start.format('h:mm')}
        </Button>
      )
    })
  }

  const renderAfternoonSlots = (closingTime) => {
    let afternoonSlots = afternoon;
    if (allowHalfHourOnly) {
      afternoonSlots = afternoon.filter(slot => slot.start.minute() !== 15 && slot.start.minute() !== 45);
    }

    return afternoonSlots.map((slot, index) => {
      const isExceedCloseTime = moment(slot.start).add(expectedDuration, 'minutes').isAfter(moment(closingTime).add(afterHoursMinute, 'minutes'));
      const disabled = slot.available ? isExceedCloseTime : true;
      let backgroundColor = 'white';
      if (disabled) {
        backgroundColor = 'transparent';
      } else if (moment(slot.start).isSame(moment(selectedDateTime))) {
        backgroundColor = 'rgb(76, 217, 100)';
      }

      return (
        <Button
          key={index}
          variant="outlined"
          disabled={disabled}
          onClick={() => handleSelectTime(moment(slot.start).toDate())}
          style={{ margin: 5, backgroundColor: backgroundColor }}
        >
          {slot.start.format('h:mm')}
        </Button>
      )
    })
  }

  const renderEveningSlots = (closingTime) => {
    let eveningSlots = evening;
    if (allowHalfHourOnly) {
      eveningSlots = evening.filter(slot => slot.start.minute() !== 15 && slot.start.minute() !== 45);
    }

    return eveningSlots.map((slot, index) => {
      const isExceedCloseTime = moment(slot.start).add(expectedDuration, 'minutes').isAfter(moment(closingTime).add(afterHoursMinute, 'minutes'));
      const disabled = slot.available ? isExceedCloseTime : true;
      let backgroundColor = 'white';
      if (disabled) {
        backgroundColor = 'transparent';
      } else if (moment(slot.start).isSame(moment(selectedDateTime))) {
        backgroundColor = 'rgb(76, 217, 100)';
      }

      return (
        <Button
          key={index}
          variant="outlined"
          disabled={disabled}
          onClick={() => handleSelectTime(moment(slot.start).toDate())}
          style={{ margin: 5, backgroundColor: backgroundColor }}
        >
          {slot.start.format('h:mm')}
        </Button>
      )
    })
  }

  const renderSelectStaff = () => {
    return selectedServices.map((service, index) => {
      const avatarGroup = [];
      const employeesList = [];
      employees.filter(employee => employee.siteIds.includes(selectedSite._id) && employee.siteIdsOnlineBooking.includes(selectedSite._id)).forEach((employee, employeeIndex) => {
        let title = '';
        let price = 0;

        
          if (employee.serviceTierId !== '') {
            if (service.tierPrices !== undefined && service.tierPrices.find(x => x.tierId === employee.serviceTierId && x.siteId === selectedSite._id) !== undefined) {
              price = Number(service.tierPrices.find(x => x.tierId === employee.serviceTierId && x.siteId === selectedSite._id).price);
            }
  
            let employeeTier = serviceTiers.find(tier => tier._id === employee.serviceTierId)
            if (employeeTier !== undefined) {
              title = employeeTier.tierName;
            }
          }
  
          if (price === 0) {
            if (service.priceInBundle !== undefined) {
              price = service.priceInBundle;
            } else {
              const priceList = service.pricesList.find(priceList => priceList.siteId === selectedSite._id);
              price = priceList.price / 100;
            }
          }

        avatarGroup.push(<Avatar key={employee._id} alt={employee.firstName + ' ' + employee.lastName} src={employee.avatarUrl} />)

        employeesList.push(
          <ListItem key={employeeIndex} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={employee.firstName + ' ' + employee.lastName} src={employee.avatarUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={employee.firstName + ' ' + employee.lastName}
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                    style={{ display: 'block' }}
                  >
                    {title === '' ? '(' + currency + price.toFixed(2) + ')' : title + ' (' + currency + price.toFixed(2) + ')'}
                  </Typography>
                  {employee.serviceSkills !== undefined && !employee.serviceSkills.includes(service._id) ?
                    <Button aria-label="select" variant="outlined" disabled startIcon={<LockIcon />} color="primary" size="small">
                      Request {employee.firstName}
                    </Button>
                    :
                    <Button
                      aria-label="select"
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => handleRequestEmployee(index, employee._id)}
                      startIcon={selectedEmployees[index] === employee._id ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : null}
                    >
                      Request {employee.firstName}
                    </Button>
                  }
                </>
              }
            />
          </ListItem>
        )

        employeesList.push(<Divider key={employeeIndex.toString() + '_divider'} variant="inset" component="li" />)
      })

      const selectedEmployee = employees.find(e => e._id === selectedEmployees[index]);

      return (
        <Accordion
          variant="outlined"
          key={index}
          TransitionProps={{
            unmountOnExit: true,
            mountOnEnter: true
          }}
        >
          <AccordionSummary>
            <Typography variant="subtitle2">
              {service.onlineBookingName.trim() !== '' ? service.onlineBookingName : service.name}&nbsp;&nbsp;
              <Typography variant="caption">{selectedEmployees[index] === 'any' ? '- with "Anyone"' : `- request "${selectedEmployee.firstName}"`}</Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <AvatarGroup max={3}>
                  {avatarGroup}
                </AvatarGroup>
                <ListItemText
                  primary="Anyone"
                  style={{ marginLeft: 10 }}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{ display: 'block' }}
                      >
                        No preference - You will see more available time slots
                      </Typography>
                      <Button
                        aria-label="select"
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleRequestEmployee(index, 'any')}
                        startIcon={selectedEmployees[index] === 'any' ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : null}
                      >
                        Request anyone
                      </Button>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              {employeesList}
            </List>
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  const renderServices = () => {
    let categoryCards = [];

    if (serviceCategories.length !== 0) {
      serviceCategories.forEach((category, index) => {
        // get available services in this category
        let availableServices = [];
        services.filter(service => service.categoryId === category._id).forEach(service => {
          if (selectedTagIds.length > 0) {
            if (matchAllTags === true) {
              let isAllTagFound = false;

              if (service.tagIds !== undefined && service.tagIds.length !== 0) {
                isAllTagFound = selectedTagIds.every(tagId => service.tagIds.includes(tagId));
              }

              if (isAllTagFound) {
                const pricesList = service.pricesList;
                for (let i = 0; i < pricesList.length; i++) {
                  if (pricesList[i].siteId === selectedSite._id && pricesList[i].isAvailable === true) {
                    availableServices.push(service);
                    break;
                  }
                }
              }
            } else {
              let isTagFound = false;

              if (service.tagIds !== undefined && service.tagIds.length !== 0) {
                isTagFound = selectedTagIds.some(tagId => service.tagIds.includes(tagId));
              }

              if (isTagFound) {
                const pricesList = service.pricesList;
                for (let i = 0; i < pricesList.length; i++) {
                  if (pricesList[i].siteId === selectedSite._id && pricesList[i].isAvailable === true) {
                    availableServices.push(service);
                    break;
                  }
                }
              }
            }
          } else {
            const pricesList = service.pricesList;
            for (let i = 0; i < pricesList.length; i++) {
              if (pricesList[i].siteId === selectedSite._id && pricesList[i].isAvailable === true) {
                availableServices.push(service);
                break;
              }
            }
          }
        });

        // get available bundles in this category
        let availableBundles = serviceBundles.filter(bundle => bundle.siteId === selectedSite._id && bundle.categoryId === category._id);
        if (selectedTagIds.length > 0) {
          if (matchAllTags === true) {
            availableBundles = availableBundles.filter(bundle => selectedTagIds.every(tagId => bundle.tagIds !== undefined && bundle.tagIds.includes(tagId)));
          } else {
            availableBundles = availableBundles.filter(bundle => selectedTagIds.some(tagId => bundle.tagIds !== undefined && bundle.tagIds.includes(tagId)));
          }
        }

        if (availableServices.length !== 0 || availableBundles.length !== 0) {
          categoryCards.push(
            <CategoryCard
              key={category._id}
              category={category}
              services={availableServices}
              handleSelectService={handleSelectService}
              handleRemoveService={handleRemoveService}
              bundles={availableBundles}
              handleSelectBundle={selectBundle}
              handleRemoveBundle={handleRemoveBundle}
              expanded={categoryCardExpand[index]}
              onExpandChange={() => handleExpandCategoryCard(index)}
              onlineBookings={true}
              selectedServices={selectedServices}
              serviceTags={serviceTags}
            />
          );
        }
      })
    }

    if (categoryCards.length === 0) {
      return (
        <Stack spacing={1}>
          {serviceTags.length !== 0 && renderFilterServiceTags()}
          <Box sx={{ p: 1 }}>
            <Typography variant="body1">No services available.</Typography>
            <Typography variant="body1">Please try another location/filter or contact us.</Typography>
          </Box>
        </Stack>
      )
    } else {
      return (
        <Stack spacing={1}>
          {serviceTags.length !== 0 && renderFilterServiceTags()}
          <Box>
            {categoryCards}
          </Box>
        </Stack>
      )
    }
  }

  const renderBundlesList = () => {
    return (
      <List dense disablePadding>
        {renderBundles()}
      </List>
    )
  }

  const renderFilterServiceTags = () => {
    return (
      <Box sx={{ p: 1 }}>
        <Grid container style={{ flexGrow: 1 }} spacing={0.5} alignItems="center">
          <Grid><Typography variant="subtitle2">Filter by:</Typography></Grid>
          {serviceTags.map((tag) => {
            return (
              <Grid key={tag._id}>
                <Badge
                  badgeContent={selectedTagIds.indexOf(tag._id) === -1 ? null : <CheckIcon />}
                  overlap="circular"
                  color="success"
                >
                  <Chip
                    label={tag.tagName}
                    style={{ backgroundColor: tag.tagColor, color: tag.textColor }}
                    size="small"
                    onClick={() => handleSelectTag(tag._id)}
                  />
                </Badge>
              </Grid>
            );
          })}
        </Grid>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={matchAllTags}
              onChange={(e) => setMatchAllTags(e.target.checked)}
              name="filterType"
              color="primary"
            />
          }
          label={<Typography variant="body2">Show services that match ALL selected tags</Typography>}
        /> */}
      </Box>
    )
  }

  const renderServiceTags = (tagIds) => {
    return tagIds.map(tagId => {
      const tag = serviceTags.find(tag => tag._id === tagId);

      return (
        <Grid key={tag._id}>
          <Chip
            label={tag.tagName}
            style={{ backgroundColor: tag.tagColor, color: tag.textColor }}
            size="small"
          />
        </Grid>
      )
    })
  }

  const renderBundles = () => {
    // get available bundles in this category
    let availableBundles = serviceBundles.filter(bundle => bundle.siteId === selectedSite._id);

    return availableBundles.map((bundle, index) => {
      return (
        <React.Fragment key={bundle._id}>
          <ListItem
            key={bundle._id}
            secondaryAction={isBigScreen &&
              <Badge
                badgeContent={selectedServices.filter(s => s.bundleName === bundle.bundleName).length / bundle.servicesInBundle.length}
                color="primary"
              >
                <ButtonGroup size="small">
                  <Button
                    edge="end"
                    color="error"
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 0 }}
                    onClick={() => handleRemoveBundle(bundle)}
                    disabled={selectedServices.filter(s => s.bundleName === bundle.bundleName).length === 0}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button edge="end" color="primary" variant="outlined" size="small" sx={{ minWidth: 0 }} onClick={() => selectBundle(bundle)}>
                    <AddIcon />
                  </Button>
                </ButtonGroup>
              </Badge>
            }
          >
            <ListItemText
              primary={
                <>{bundle.bundleName} <Chip icon={<Iconify icon="fluent:box-multiple-20-regular" />} label="Bundle" size="small" component="span" /></>
              }
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <Stack spacing={0.25}>
                  {bundle.description !== undefined && bundle.description !== '' &&
                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                      style={{ display: 'block' }}
                    >
                      <i>{bundle.description}</i>
                    </Typography>
                  }
                  {bundle.tagIds !== undefined && bundle.tagIds.length > 0 &&
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                      {renderServiceTags(bundle.tagIds)}
                    </Grid>
                  }
                  {!isBigScreen &&
                    <Box>
                      <Badge
                        badgeContent={selectedServices.filter(s => s.bundleName === bundle.bundleName).length / bundle.servicesInBundle.length}
                        color="primary"
                      >
                        <ButtonGroup size="small">
                          <Button
                            edge="end"
                            color="error"
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 0 }}
                            onClick={() => handleRemoveBundle(bundle)}
                            disabled={selectedServices.filter(s => s.bundleName === bundle.bundleName).length === 0}
                          >
                            <RemoveIcon />
                          </Button>
                          <Button edge="end" color="primary" variant="outlined" size="small" sx={{ minWidth: 0 }} onClick={() => selectBundle(bundle)}>
                            <AddIcon />
                          </Button>
                        </ButtonGroup>
                      </Badge>
                    </Box>
                  }
                </Stack>
              }
            />
          </ListItem>
          {index !== availableBundles.length - 1 && <Divider component="li" />}
        </React.Fragment>

        // <ListItemButton key={index} dense onClick={() => selectBundle(bundle)}>
        //   <ListItemText
        //     primary={
        //       <Typography variant="body2">{bundle.bundleName} <Chip icon={<Iconify icon="fluent:box-multiple-20-regular" />} label="Bundle" size="small" component="span" /></Typography>
        //     }
        //     secondaryTypographyProps={{ component: 'div' }}
        //     secondary={bundle.description !== undefined && bundle.description !== '' &&
        //       <>
        //         <Typography
        //           component="span"
        //           variant="caption"
        //           color="textPrimary"
        //           style={{ display: 'block' }}
        //         >
        //           <i>{bundle.description}</i>
        //         </Typography>
        //         {bundle.tagIds !== undefined && bundle.tagIds.length > 0 &&
        //           <Grid container style={{ flexGrow: 1 }} spacing={2}>
        //             {renderServiceTags(bundle.tagIds)}
        //           </Grid>
        //         }
        //       </>
        //     }
        //   />
        //   {selectedServices.find(s => s.bundleName === bundle.bundleName) !== undefined && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
        // </ListItemButton>
      )
    })
  }

  const renderClassesList = () => {
    return (
      <List>
        {renderClasses()}
      </List>
    )
  }

  const renderClasses = () => {
    return classesList.filter(c => c.siteId === selectedSite._id).map((c, index) => {
      return (
        <ListItemButton key={index} dense onClick={() => selectClass(c)}>
          <ListItemText primary={c.className} />
          {selectedClass.find(cl => cl.className === c.className) !== undefined && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
        </ListItemButton>
      )
    })
  }

  const renderLocationsRows = () => {
    return sites.map((site, index) => {
      const isSelected = (selectedSite !== null && selectedSite._id === site._id ? true : false);

      return (
        <React.Fragment key={site._id}>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
                <Stack spacing={1} direction="row" alignItems="center">
                  <StorefrontIcon />
                  <Typography variant="subtitle1">{site.name}</Typography>
                </Stack>
                <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => selectSite(site, stripeDefaultConnectedId)}
                    startIcon={isSelected ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : null}
                  >
                    {isSelected ? 'Selected' : 'Book Here'}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleExpandSiteCard(index)}
                    endIcon={siteExpand[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  >
                    {siteExpand[index] ? "Hide Details" : "View Details"}
                  </Button>
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Collapse in={siteExpand[index]} timeout="auto" unmountOnExit>
                <Box sx={{ m: 1 }}>
                  <Stack direction="column" spacing={1}>
                    <Chip size="small" icon={<PinDropIcon />} label={site.address} sx={{
                        height: 'auto',
                        p: 0.5,
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                        },
                      }}
                    />
                    <Chip size="small" icon={<PhoneIcon />} label={site.phone} />
                    <Chip size="small"
                      sx={{
                        height: 'auto',
                        p: 1,
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'normal',
                        },
                      }}
                      label={renderOpeningHours(site.openingHours)}
                    />
                  </Stack>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )
    })
  }

  const renderOpeningHours = (openingHours) => {
    return openingHours.map((day, index) => {
      let openHour = day.values.min - (day.values.min % 1);
      openHour = openHour > 12 ? openHour - 12 : openHour;
      let openMinute = day.values.min % 1 === 0 ? ':00' : ':30';
      let openAMPM = day.values.min < 12 ? ' AM' : ' PM';
      const opening = openHour + openMinute + openAMPM

      let closeHour = day.values.max - (day.values.max % 1);
      closeHour = closeHour > 12 ? closeHour - 12 : closeHour;
      let closeMinute = day.values.max % 1 === 0 ? ':00' : ':30';
      let closeAMPM = day.values.max < 12 ? ' AM' : ' PM';
      const closing = closeHour + closeMinute + closeAMPM

      return (
        <Stack key={index} spacing={1} direction="row">
          <Typography variant="caption" sx={{ width: 50 }}>{day.day.substring(0,3)}</Typography>
          {day.active === false ?
            <Typography variant="caption">Closed</Typography>
            :
            <Typography variant="caption">{opening + ' - ' + closing}</Typography>
          }
        </Stack>
      )
    })
  }

  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>{businessName === '' ? 'Client Diary' : businessName}</title>
      </Helmet>

      { loading ?
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
        </div>
        :
        <div className={classes.root}>
          <div style={getComputedStyle()}>
            {bannerImage === '' &&
              <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>
                    {businessName}
                  </Typography>
                </Toolbar>
              </AppBar>
            }
            <Container fixed sx={{ pb: 2 }}>
              <Stack spacing={2} alignItems="center">
                {bannerImage !== '' && <img src={bannerImage} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="banner" />}
                {renderContentV2()}
              </Stack>
            </Container>
            <Dialog
              open={termsDialogOpen}
              disableEscapeKeyDown
              maxWidth={false}
            >
              <DialogTitle>
                Terms & Conditions
              </DialogTitle>
              <DialogContent>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {termsAndConditions}
                </div>
              </DialogContent>
              <DialogActions>
                <Button color="error" onClick={() => setTermsDialogOpen(false)}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div style={{ backgroundColor: 'rgb(56, 67, 76)' }}>
            <div className="container">
              <div className="center-xs" style={{ margin: 10 }}>
                <div style={{ color: 'white', fontSize: 13 }}>
                  Online Booking powered by <a href="https://clientdiary.com" target="_blank" rel="noreferrer" style={{ color: 'white' }}><b><i>Client Diary</i></b></a>
                </div>
                <div style={{ color: 'white', fontSize: 11, display: (getAuthor() === '' ? 'none' : '') }}>
                  Background image by {getAuthor()} from all-free-download.com
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default App;

const CustomDay = (props) => {
  const invisible = props.outsideCurrentMonth || props.disabled;

  const isSelected =
    !props.outsideCurrentMonth &&
    !props.disabled &&
    props.highlightedDays.indexOf(props.day.date()) !== -1

  if (isSelected) {
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        color={isSelected ? "success" : "error"}
        variant="dot"
        invisible={invisible}
      >
        <PickersDay {...props} />
      </Badge>
    );
  } else {
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={<span style={{ fontSize: 8 }}>❌</span>}
        invisible={invisible}
      >
        <PickersDay {...props} />
      </Badge>
    );
  }
}